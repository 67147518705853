import React from "react"
import { useStaticQuery } from "gatsby"
import styles from "./testimonial.module.scss"
import Container from "../../components/container/container"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const query = graphql`
  query TestimonialQuery {
    strapiGlobal {
      Testimonials {
        Body
        Name
        Role
      }
    }
  }
`
const Testimonial = props => {
  const data = useStaticQuery(query)
  const testimonials = shuffle(data.strapiGlobal.Testimonials)
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  return (
    <div className={`${styles.testimonial} ${styles[props.bgClass]}`}>
      <Container>
        <Slider {...settings}>
          {testimonials.map((item, key) => (
            <div key={key}>
              <p className={styles.testimonial__body}> {item.Body}</p>
              <p className={styles.testimonial__name}> {item.Name}</p>
              <p className={styles.testimonial__role}> {item.Role}</p>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default Testimonial
